import assetVideo from "./asset-video";

const PIXELS_PRECISION_VIDEOS = 100;
const MAX_WIDTH_VIDEOS = 2000;
const MAX_HEIGHT_VIDEOS = 2000;

let plyrStyleConfigured = false;
const downloadedLibs = new Set();
const videoDebug = false; // Enable it to debug player

const lazyVideoHelper = {
  addLazyVideos(baseFind) {
    const videoSelectors = [".video-generic", ".video-youtube", ".video-vimeo"];
    const observer = new IntersectionObserver(this.handleIntersection.bind(this), { threshold: [0] });

    videoSelectors.forEach((selector) => {
      baseFind.querySelectorAll(selector).forEach((element, i) => {
        element.id += Date.now() + i;
        if (element.getAttribute("vdmodal") !== "true") {
          observer.observe(element);
        }
      });
    });
  },

  handleIntersection(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.lazyLoad(entry.target);
        observer.unobserve(entry.target);
      }
    });
  },

  lazyLoad(lazyVideo) {
    if (lazyVideo.id.includes("GNPlayer")) {
      this.setupCustomPlayer(lazyVideo);
    } else if (lazyVideo.getAttribute("vdautoplay") === "true") {
      lazyVideo.src = lazyVideo.src.replace("&autoplay=0", "&autoplay=1");
    }
  },

  setupCustomPlayer(lazyVideo) {
    this.setPosterURL(lazyVideo);

    if (lazyVideo.getAttribute("vdautoplay") === "true") {
      Object.assign(lazyVideo, {
        muted: true,
        autoplay: true,
        loop: true,
        playsInline: true,
      });
    }

    assetVideo.setupBestSource(lazyVideo);

    const dynamicMedia = lazyVideo.getAttribute("dynamicMedia") === "true";
    const canPlayNativeDM = lazyVideo.getAttribute("canPlayNativeDM") === "true";

    if (dynamicMedia && canPlayNativeDM) {
      lazyVideo.load();
    } else {
      this.customPlayerLoadAndPlay(lazyVideo);
    }
  },

  disableCustomPlayer(lazyVideo) {
    window.barceloActivePlayers = window.barceloActivePlayers.filter((player) => {
      if (player.media.id === lazyVideo.id) {
        player.destroy();
        return false;
      }
      return true;
    });
  },

  async customPlayerLoadAndPlay(video) {
    await this.loadPlyrLibs();
    this.configurePlyrStyle();

    const player = new Plyr(video, this.getPlyrConfig(video));
    this.setupPlyrEvents(player);
    this.setupHlsIfNeeded(video);
  },

  loadPlyrLibs() {
    return Promise.all([this.downloadExternalLib("https://cdn.jsdelivr.net/npm/plyr@3/dist/plyr.min.js", "js"), this.downloadExternalLib("https://cdn.jsdelivr.net/npm/plyr@3/dist/plyr.min.css", "css")]);
  },

  configurePlyrStyle() {
    if (plyrStyleConfigured) {
      return;
    }

    plyrStyleConfigured = true;
    const plyrStyle = document.createElement("style");
    plyrStyle.textContent = `
      .plyr {height: 100%; width: 100%;}
      .plyr__poster {width: 100%; height: 100%; object-fit: cover; background-size: cover !important;}
      .plyr--video {position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover;}
    `;
    document.head.appendChild(plyrStyle);
  },

  getPlyrConfig(video) {
    return {
      debug: videoDebug,
      autoplay: video.autoplay,
      muted: video.muted,
      loop: { active: video.loop },
      playsinline: true,
      controls: video.controls ? ["play-large", "play", "progress", "current-time", "mute", "volume", "settings", "pip", "airplay", "fullscreen"] : ["play-large"],
      hideControls: true,
      settings: ["speed", "loop"],
      clickToPlay: true,
      disableContextMenu: true,
      displayDuration: true,
      invertTime: true,
      autopause: true,
      keyboard: { focused: true, global: false },
      fullscreen: { enabled: true, fallback: true, iosNative: false, container: null },
      storage: { enabled: false, key: "plyr" },
      speed: { selected: 1, options: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] },
      captions: { active: false },
      ads: { enabled: false },
    };
  },

  setupPlyrEvents(player) {
    const checkPlayButton = () => {
      const playButton = player.elements.buttons?.play?.[0];
      if (playButton) {
        if (player.autoplay) {
          playButton.style.display = "none";
        } else {
          playButton.style.display = "block";
        }
      }
    };
    checkPlayButton();

    ["ready", "progress", "playing", "pause"].forEach((event) => {
      player.on(event, (e) => {
        if (videoDebug) {
          console.log(`Player ${event}: `, e);
        }
      });
    });

    window.barceloActivePlayers = window.barceloActivePlayers || [];
    window.barceloActivePlayers.push(player);
  },

  async setupHlsIfNeeded(video) {
    if (this.isDynamicMediaConfigured(video)) {
      await this.downloadExternalLib("https://cdn.jsdelivr.net/npm/hls.js@1/dist/hls.min.js", "js");
      const hls = new Hls(this.getHlsConfig());
      hls.loadSource(video.querySelector(".videoDynamicPath").src);
      hls.attachMedia(video);
      if (videoDebug) {
        window.hls = hls;
      }
    } else {
      video.src = video.querySelector(".videoDynamicPath").src;
      video.load();
    }
  },

  isDynamicMediaConfigured(video) {
    return (video.getAttribute("videodesktoppathdm") !== "DM-not-configured" || video.getAttribute("videomobilepathdm") !== "DM-not-configured") && video.getAttribute("dynamicmedia") === "true";
  },

  getHlsConfig() {
    return {
      debug: videoDebug, // Keep debug enabled if necessary for development
      autoStartLoad: true, // Start loading automatically
      startPosition: -1, // Allow HLS.js to determine the best initial position
      startLevel: -1, // Start with the best quality level
      capLevelToPlayerSize: true, // Limit the quality level to the player size
      ignoreDevicePixelRatio: true, // Ignore the device pixel ratio to adjust quality
      maxBufferLength: 10, // Reduce the maximum buffer size to decrease memory usage
      maxMaxBufferLength: 20, // Limit the maximum buffer size under optimal conditions
      backBufferLength: 5, // Keep less back buffer to save memory
      maxBufferSize: 60 * 1000 * 1000, // Limit the maximum buffer size in bytes
      maxBufferHole: 0.5, // Allow a larger buffer hole before performing a search
      enableWorker: true, // Use Web Worker to improve performance
      enableSoftwareAES: true, // Software AES for better compatibility
      maxFragLookUpTolerance: 0.2, // Fragment search tolerance
      testBandwidth: true, // Test bandwidth to adjust quality
      progressive: false, // Disable progressive loading
      lowLatencyMode: true, // Enable low latency mode
      startFragPrefetch: true, // Prefetch the first fragment
      enableWebVTT: false, // Disable WebVTT if not necessary
      enableLowInitialPlaylist: true, // Enable low initial playlist
      liveSyncDurationCount: 3, // Adjust live sync for lower latency
      liveMaxLatencyDurationCount: 5, // Limit maximum live latency
      maxLoadingDelay: 4, // Limit the maximum waiting time to load a fragment
      fragLoadingTimeOut: 20000, // Increase the timeout for fragment loading (20s)
      fragLoadingMaxRetry: 6, // Increase the maximum number of fragment loading retries
      fragLoadingRetryDelay: 1000, // Delay between fragment loading retries (1s)
      fragLoadingMaxRetryTimeout: 64000, // Maximum accumulated time for fragment loading retries (64s)
    };
  },

  modalCheckEnableVideo(element) {
    if (element.id.includes("GNPlayer")) {
      this.setupCustomPlayer(element);
    } else if (element.getAttribute("vdautoplay") === "true") {
      element.src = element.src.replace("&autoplay=0", "&autoplay=1");
    }
    this.watchModalVideo(element);
  },

  modalCheckDisableVideo(element) {
    if (element.id.includes("GNPlayer")) {
      this.disableCustomPlayer(element);
    } else {
      element.src = element.src.replace("&autoplay=1", "&autoplay=0");
    }
  },

  watchModalVideo(element) {
    const modal = element.closest(".tingle-modal, .brh-video__modal");
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes" && mutation.attributeName === "class") {
          this.modalCheckDisableVideo(element);
          observer.disconnect();
        }
      });
    });
    observer.observe(modal, { attributes: true });
  },

  setPosterURL(video) {
    const urlPoster = video.dataset.posterpreview;
    fetch(`${urlPoster}/jcr:content/metadata.infinity.json`, {
      headers: { "Content-Type": "application/json", Accept: "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        const posterUrl = this.constructPosterURL(data, video);
        video.dataset.poster = posterUrl;
      })
      .catch((error) => console.info("No metadata found: " + error));
  },

  constructPosterURL(data, video) {
    if (data["dam:scene7Name"]) {
      const posterQuality = bcl.u.mobile.isMobile() ? "1" : "45";
      const timestamp = Date.now();
      const posterHeight = this.getOptimalHeight(video);
      const posterWidth = this.getOptimalWidth(video);
      const imageServer = `${data["dam:scene7Domain"]}is/image/`;
      return `${imageServer}${data["dam:scene7Name"]}?wid=${posterWidth}&hei=${posterHeight}&fit=constrain&qlt=${posterQuality}&op_usm=1.1,1&time=${timestamp}`;
    } else {
      return video.dataset.posterpreview;
    }
  },

  getOptimalWidth(video) {
    return Math.min(video.clientWidth + PIXELS_PRECISION_VIDEOS, MAX_WIDTH_VIDEOS);
  },

  getOptimalHeight(video) {
    return Math.min(video.clientHeight + PIXELS_PRECISION_VIDEOS, MAX_HEIGHT_VIDEOS);
  },

  async downloadExternalLib(url, type) {
    if (downloadedLibs.has(url)) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      let element;
      if (type === "js") {
        element = document.createElement("script");
        element.src = url;
        element.async = true;
      } else if (type === "css") {
        element = document.createElement("link");
        element.rel = "stylesheet";
        element.href = url;
      } else {
        reject(new Error("Unsupported file type for downloading"));
      }

      element.onload = function () {
        downloadedLibs.add(url);
        resolve();
      };

      element.onerror = reject;
      document.head.appendChild(element);
    });
  },
};

export default lazyVideoHelper;
