import { dynamicImport, isPresent as isComponentPresent } from "components-utils";
import BRHComponents from "barcelo-components/brh-components";
import StructureComponents from "barcelo-components/structure";
import HotelComponents from "barcelo-components/hotel";
import GenericComponents from "barcelo-components/generic";
import ListingComponents from "barcelo-components/listing";
import AdvComponents from "barcelo-components/adv";
import MenuComponents from "barcelo-components/menu";
import VariousComponents from "barcelo-components/various";
import TeasersComponents from "barcelo-components/teasers";
import FaqComponents from "barcelo-components/faq";
import MyBarceloComponents from "barcelo-components/mybarcelo";

const components = {
  ...AdvComponents,
  ...BRHComponents,
  ...StructureComponents,
  ...HotelComponents,
  ...GenericComponents,
  ...ListingComponents,
  ...MenuComponents,
  ...VariousComponents,
  ...TeasersComponents,
  ...FaqComponents,
  ...MyBarceloComponents,
};

/**
 * @param selector - component selector, if an array of selector is passed it will return if some is present
 * @param path - path from components/barcelo directory
 * @param initMethod - method name to initialize component
 * @param skipInit - not initialize on import
 * @param belongsTo - parent component
 * @param customName
 * @param forceImport
 * @returns {{isPresent: (function(): *), importComponent: (function({name: *}): *)}}
 * @constructor
 */
export function BarceloComponent({ selector, path, initMethod = "init", skipInit = false, belongsTo = "", customName = "", forceImport = false }) {
  return {
    isPresent: ({ root = document, ignoreForceImport = false, ignoreIsPresent = false } = {}) => {
      return ignoreIsPresent || (!ignoreForceImport && forceImport) || isComponentPresent(selector, root);
    },
    importComponent: async ({ name, root }) => {
      const component = await import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "[request]" */
        `./${path}.js`
      );
      return dynamicImport({
        customName,
        name,
        component,
        skipInit,
        belongsTo,
        initMethod,
        root,
      });
    },
  };
}

export default components;
